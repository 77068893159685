.loginTitle {
  margin-top: 20px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.login {
  width: 100%;
  height: 60vh;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 80px;
}
.login .Form {
  width: 100%;
  padding: 0 20px;
}
.login .Form .input__field--minoru {
  width: 100%;
  height: 50px;
  /* background: #fff; */
  box-shadow: 0px 0px 0px 2px transparent;
  color: #000000;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.login .Form .input__field {
  position: relative;
  display: block;
  padding: 1em;
  width: 100%;
  border: none;
  border-radius: 20px;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-appearance: none;
}
.password {
  -webkit-text-security: disc;
  -moz-text-security: disc;
}

.tab {
  width: 100%;
  height: 50px;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #ffffff;
  padding: 34px 0;
  z-index: 9999;
}
.tab div a {
  display: block;
  display: flex;
  text-decoration: none;
  color: #000000;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
}
.tab div img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
}
.tab div .meImg {
  border-radius: 0px;
}

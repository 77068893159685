.Layout {
  width: 100%;
  /* color: pink; */
}
.admin {
  color: #a0a0a0;
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}
.cententer {
  width: 100%;
  /* height: 200px; */
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 50px;
}
.cententer .activity {
  display: block;
  /* border: 1px solid #a0a0a0; */
  background-color: #1677ff;
  padding: 30px 10px;
  font-size: 16px;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
}
.cententer .activityB {
  display: block;
  /* border: 1px solid #a0a0a0; */
  background-color: #00b578;
  padding: 30px 10px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  border-radius: 10px;
  color: #ffffff;
}
.flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
input {
  line-height: normal;
  position: relative;
  z-index: 1;
  display: inline-block;
  /* margin: 1em; */
  max-width: 400px;
  width: calc(100% - 2em);
  outline: medium;
  border: none;
  vertical-align: top;
}
.flex span {
  flex: 1;
}
.flex span .input__field--minoru {
  width: 100%;
  height: 50px;
  /* background: #fff; */
  box-shadow: 0px 0px 0px 2px transparent;
  color: #eca29b;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.flex span .input__field {
  position: relative;
  display: block;
  padding: 0.6em;
  width: 90%;
  border: none;
  border-radius: 0;
  /* background: #a0a0a0; */
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-appearance: none;
}
.flex div {
  width: 20%;
  height: 50px;
  /* padding: 0.6em; */
  background-color: #1890ff;
  text-align: center;
  line-height: 50px;
  font-size: 16px;
  color: #ffffff;
  border-radius: 5px;
}
.activityListA {
  position: relative;
  margin: 0 0px 50px 0px;
  padding: 20px 20px;
  /* flex: 1; */
  height: 70vh;
  /* border: 1px solid greenyellow;
  display: flex;
  flex-direction: column;
  justify-content: space-between; */
  overflow: auto;
}
.activityListA::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
}
.activityListA::-ms-overflow-style:none .activityListA .ok {
  align-self: flex-end;
  padding: 10px;
  background-color: #1890ff;
  border-radius: 5px;
  color: #ffffff;
}

.activityList {
  position: relative;
  margin: 0 20px 100px 20px;
  flex: 1;
  height: 70vh;
  overflow: auto;
}
.activityList::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
}
.activityList::-webkit-scrollbar {
  display: none;
}
.activityListA .OrderList {
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.activityListA .OrderList .OrderList_orderID {
  margin: 10px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.activityListA .OrderList .OrderList_img {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.activityListA .OrderList .OrderList_img .OrderList_left {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}
.activityListA .OrderList .OrderList_price {
  width: 100%;
  display: flex;
  justify-content: end;
  padding-top: 10px;
}
.activityListA .OrderList + .OrderList {
  margin-top: 10px;
}
.activityListA .NoList {
  width: 100%;
  /* height: 200px; */
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 50px;
}

.activityList .OrderList {
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.activityList .OrderList .OrderList_orderID {
  margin: 10px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.activityList .OrderList .OrderList_img {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.activityList .OrderList .OrderList_img .OrderList_left {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}
.activityList .OrderList .OrderList_price {
  width: 100%;
  display: flex;
  justify-content: end;
  padding-top: 10px;
}
.activityList .OrderList + .OrderList {
  margin-top: 10px;
}
.activityList .NoList {
  width: 100%;
  /* height: 200px; */
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 50px;
}

.NoList img {
  width: 130px;
  height: 130px;
}
.NoList span {
  font-size: 18px;
  color: #000000;
}
.logSearch {
  display: flex;
  justify-content: space-between;
}
.logSearch .logSearch_left {
  display: flex;
}
.logSearch .logSearch_left div {
  padding-right: 20px;
}
.changeOntap {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.changeOntap div {
  padding: 10px 20px;
  font-size: 16px;
  color: #1890ff;
}
